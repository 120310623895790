export const COLOR_VIOLET = "violet";
export const COLOR_PURPLE = "purple";
export const COLOR_ORANGE = "orange";
export const COLOR_GREEN = "green";
export const COLOR_BLUE = "blue";

export const COLOR_PRIMARY = "#00C5CD";
export const COLOR_PRIMARY_HOVER = "#13949A";
export const COLOR_SECONDARY = "#084C61";
export const COLOR_SECONDARY_DISABLED = "#467989";
export const COLOR_TERTIARY = "#767680";
export const COLOR_LIGHT = "#F3F3F7";

export const baseColorHex = {
  primary: "0, 197, 205",
  secondary: "8, 76, 97",
  tertiary: "118, 118, 128",
  violet: "125, 87, 161",
  purple: "199, 26, 136",
  orange: "235, 102, 23",
  green: "68, 167, 31",
  bue: "0, 140, 214",
};

export const chartColors = [
  "primary",
  "secondary",
  "tertiary",
  "violet",
  "purple",
  "orange",
  "green",
  "blue",
];

export const { REACT_APP_AM_CHARTS_LICENCE, REACT_APP_AM_CHARTS_MAPS_LICENCE } = process.env;
